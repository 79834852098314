import Navigation from './components/navigation';
import IndexAbout from './components/indexAbout';
import IndexLatest from './components/indexLatest';
import Footer from './components/footer';
import Imprint from './components/imprint';
import Privacy from './components/privacy';
import IndexCertifications from './components/indexCertifications';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Waitlist from './components/Waitlist';

function App() {
  return (
    <Router>
      <Routes>
            <Route path='' element={<><Navigation /><IndexAbout /><IndexLatest /><IndexCertifications/><Footer/></>}></Route>
            <Route path='/imprint' element={<><Navigation /><Imprint/><Footer/></>}></Route>
            <Route path='/privacy' element={<><Navigation /><Privacy/><Footer/></>}></Route>
            <Route path='/projects/kapital' element={<><Navigation /><Footer/></>}></Route>
            <Route path='/projects/dopnique' element={<><Navigation /><Footer/></>}></Route>
            <Route path='/aidevtools-waitlist' element={<><Navigation /><Waitlist/><Footer/></>}></Route>
      </Routes>
      </Router>
  );
}

export default App;
