import React from 'react';

function Waitlist() {
  return (
    <div>
      <div id="getWaitlistContainer" data-waitlist_id="25033" data-widget_type="WIDGET_1"></div>
<link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
<script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
    </div>
  );
}

export default Waitlist;
